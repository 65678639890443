<template>
  <v-dialog v-model="active" width="70%" v-if="order" :fullscreen="isNotPc">
    <v-card class="pb-5 pt-15 px-md-10 order-dialog" style="position: relative">
      <!-- x btn -->
      <v-row class="justify-center mb-8">
        <h1
          class="text-h6 mt-md-0 mt-16"
          v-if="uiAuth({ type, authArray: [0, 1, 2, 4] })"
        >
          {{ order.menu.title }}
        </h1>
      </v-row>
      <div
        class="d-flex"
        style="position: absolute; left: 20px; top: 15px; gap: 15px"
      >
        <v-btn
          icon
          color="black"
          v-if="uiAuth({ type, authArray: [0] })"
          @click="editOrder"
        >
          <v-icon>mdi-pencil</v-icon> </v-btn
        ><v-btn
          icon
          @click="active = false"
          color="black"
          v-if="uiAuth({ type, authArray: [0] })"
          :href="pdfUrl"
          target="_blank"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn icon @click="active = false" color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <!-- the two boxes at the top -->
      <v-row class="mb-10 flex-column-not-pc">
        <v-col
          class="card-border mx-0 mx-md-10 d-flex flex-column rounded-xl pa-5"
        >
          <div class="d-flex justify-space-between">
            <span v-if="order.client" class="font-weight-bold">{{
              order.client.fullname
            }}</span>
            <span
              ><span
                v-if="uiAuth({ type, authArray: [1, 2, 3, 4] })"
                class="font-weight-bold"
                >{{ $t('order.num') }}: </span
              >{{ order.orderNum }}</span
            >
          </div>

          <span v-if="order.client"
            ><span class="font-weight-bold">{{ $t('generics.mobile') }}: </span
            ><span>{{ order.client.phone }}</span></span
          >
          <span v-if="order.info && uiAuth({ type, authArray: [4] })"
            ><span class="font-weight-bold">{{ $t('generics.contact') }}: </span
            >{{ order.info }}</span
          >
        </v-col>
        <v-col
          class="card-border mx-0 mx-md-10 mt-4 mt-md-0 flex-column d-flex rounded-xl"
        >
          <span v-if="uiAuth({ type, authArray: [0, 1, 2, 4] })"
            ><span class="font-weight-bold">{{ $t('generics.date') }}: </span
            >{{ convertDate(order.delivery.day) }}</span
          >
          <span v-if="uiAuth({ type, authArray: [0, 4] })">{{
            order.delivery.type === 'delivery'
              ? $t('delivery.index')
              : $t('generics.selfPickUp')
          }}</span>
          <span
            ><span class="font-weight-bold">{{ $t('generics.hour') }}: </span
            >{{ order.delivery.startHour }}
            <span
              v-if="order.additionalInfo && uiAuth({ type, authArray: [4] })"
              >({{ order.additionalInfo }})</span
            ></span
          >

          <span
            v-if="order.delivery.address && uiAuth({ type, authArray: [4] })"
            class="font-weight-bold"
            style="white-space: pre"
            >{{ order.delivery.address }}</span
          >
          <span v-if="order.delivery.info && uiAuth({ type, authArray: [4] })">
            {{ order.delivery.info }}</span
          >
          <span
            v-if="
              (order.signature || order.delivery.receiver) &&
              order.delivery.deliveredTime
            "
            ><span class="font-weight-bold"
              >{{ $t('delivery.deliveredTime') }}:
            </span>
            <span>{{
              dayjs(+order.delivery.deliveredTime).format('DD/MM/YYYY HH:mm')
            }}</span></span
          >
        </v-col>
      </v-row>
      <div class="d-flex flex-column ms-8 mb-4">
        <span
          v-if="
            order.serveInfo &&
            order.serveInfo !== 'no-mention' &&
            uiAuth({ type, authArray: [0] })
          "
          >{{ $t(`delivery.servingTypes.${order.serveInfo}`) }}</span
        >
        <span>{{ order.menuInfo }}</span>
      </div>
      <div v-if="order.delivery.type === 'delivery'" class="center-all">
        <v-checkbox
          v-model="shipperCollected"
          :label="`${$t('delivery.shipperCollected')} ${
            shipperCollected
              ? `(${dayjs(+order.delivery.deliveryManPickupTime).format(
                  'DD/MM/YYYY HH:mm'
                )})`
              : ''
          }`"
        ></v-checkbox>
      </div>
      <v-radio-group
        v-if="order.delivery.type === 'delivery'"
        v-model="driver"
        class="v-input-hours"
      >
        <v-radio
          v-for="value in drivers"
          :key="value._id"
          :label="`${value.fullname}`"
          :value="value._id"
        ></v-radio>
      </v-radio-group>
      <v-row
        style="gap: 8px; max-width: 600px; flex-wrap: nowrap"
        class="mt-n4 mb-4 mx-auto"
        v-if="driver === 'else'"
      >
        <v-text-field
          outlined
          v-model="otherDriver"
          :label="$t('userDetails.fullName')"
        />
        <v-text-field
          v-model="otherDriverPhone"
          outlined
          :label="$t('userDetails.phone')"
        />
      </v-row>

      <!-- the table -->
      <v-data-table
        :headers="tableHeaders"
        :items="countedMenu"
        :items-per-page="10"
        :item-class="itemRowBackground"
        dense
        class="mb-10"
        :footer-props="{ 'items-per-page-options': [100, 30, 50, -1] }"
      >
        <template v-slot:item.checkbox="{ item }">
          <v-checkbox
            v-if="item.product.title !== 'משלוח'"
            v-model="item.isPicked"
            @change="handleProductPick(item)"
            class="big-checkbox"
            :disabled="!item.isReady || !{ 0: 1, 1: 1 }[$store.getters.role]"
          />
        </template>
        <template v-slot:item.image="{ item }">
          <image-show :src="`/ uploads / ${item.product.image} `" />
        </template>
        <template v-slot:item.product.info="{ item }">
          <div class="d-flex justify-space-between">
            <span>{{ item.product.info }}</span>
            <v-tooltip
              bottom
              v-if="
                uiAuth({ type, authArray: [1] }) &&
                isAfterYesterdayAt10(order.updatedAt)
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-alert</v-icon>
              </template>
              {{
                `${$t('generics.changes')} -${calcRelativeDateString(
                  order.updatedAt
                )} `
              }}
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.notReadyFromKitchen="{ item }">
          <span v-if="!item.isReady">
            {{ $t('generics.notReadyFromKitchen') }}</span
          >
        </template>
      </v-data-table>
      <v-row class="mb-10 flex-column-not-pc" style="gap: 20px 0">
        <v-col
          class="card-border mx-md-10 d-flex flex-column rounded-xl pa-5 align-center-not-pc"
        >
          <div
            md="3"
            sm="4"
            class="d-flex align-center justify-center mb-4 mb-md-0"
          >
            <span
              :class="`payed-${paymentStatus.status}`"
              class="payment-status py-2 px-5 rounded-lg"
              >{{ paymentStatus.text }}</span
            >
          </div>
          <span>
            <span class="font-weight-bold mx-1"
              >{{ $t('generics.sumToPay')
              }}{{
                order.menu.discount
                  ? ` (${$t('order.includedDiscount', {
                      num: order.menu.discount,
                    })})`
                  : ''
              }}: {{ totalPrice }}</span
            >{{ $t('generics.ils') }}</span
          >
          <span v-if="order.infoOnPayment">{{ order.infoOnPayment }}</span>
          <div style="height: 10px"></div>
          <v-btn
            v-if="order.linkToPayment"
            @click="copyLink(order.linkToPayment)"
            color="primary"
            style="width: 200px"
          >
            <span
              >{{ $t('generics.copyPayLink') }}
              <v-icon>mdi-link-variant </v-icon></span
            >
          </v-btn>
          <div
            class="mt-4 d-flex align-center flex-column-not-pc"
            style="gap: 20px"
          >
            <v-text-field
              v-model="receiptIdComputed"
              solo
              outlined
              :label="$t('order.receptionNumber')"
              :disabled="!!order.paymentData.receiptId"
              style="min-width: 240px"
            ></v-text-field>
            <v-btn
              v-if="!order.paymentData.receiptUrl"
              style="min-width: 150px"
              @click="approvePayment"
              color="primary"
            >
              <v-icon v-if="order.paymentData.receiptId">mdi-delete</v-icon>
              <span v-else>
                <span>{{ $t('order.payed') }}</span>
              </span>
            </v-btn>
          </div>
        </v-col>
        <v-col class="card-border mx-md-10 d-flex flex-column rounded-xl pa-5">
          <span
            style="width: 200px; margin: auto"
            :class="`payed-${order.status === 'waiting' ? 'false' : 'true'}`"
            class="py-2 px-5 rounded-lg center-all"
            >{{ $t(`order.${order.status}`) }}</span
          >
          <span
            ><span class="font-weight-bold">{{ $t('order.numOfBoxes') }}: </span
            >{{ numOfBoxes(order) }}</span
          >

          <div class="mt-4 d-flex align-center" style="gap: 20px">
            <v-text-field
              v-model="receiverComputed"
              solo
              outlined
              :label="$t('order.fetchName')"
              :disabled="!collectedAll || !!order.delivery.receiver"
            ></v-text-field
            ><v-btn
              style="min-width: 150px"
              @click="approveDeliveryHandler"
              color="primary"
              :disabled="!collectedAll"
            >
              <v-icon v-if="order.delivery.receiver">mdi-delete</v-icon>
              <span v-else>
                <span v-if="order.status !== 'delivered'">{{
                  $t('order.approveDelivery')
                }}</span>
                <span v-else>{{ $t('order.updateReceiver') }}</span>
              </span>
            </v-btn>
          </div>
          <div
            class="mt-4 d-flex align-center flex-column-not-pc"
            style="gap: 20px"
          >
            <div
              :style="`
                border: black 1px solid;
                width: 350px;
                height: ${isNotPc ? 100 : 60}px;
                position: relative;
              `"
            >
              <v-img
                v-if="order.signature"
                :style="`height: ${isNotPc ? 94 : 54}px`"
                :src="`/uploads/signature/${
                  order._id
                }.jpeg?x=${dayjs().unix()}`"
              />
              <vueSignature
                v-else
                :w="`${isNotPc ? '100%' : '300px'}`"
                :h="`${isNotPc ? 94 : 54}px`"
                ref="signature"
              />
            </div>
            <v-btn
              :disabled="!collectedAll"
              style="min-width: 150px"
              @click="clientSign"
              color="primary"
            >
              <v-icon v-if="order.signature">mdi-delete</v-icon>
              <span v-else>
                <span v-if="order.status !== 'delivered'">{{
                  $t('order.approveDelivery')
                }}</span>
                <span v-else>{{ $t('order.updateReceiverSignature') }}</span>
              </span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs'
import Times from '../helpers/times'
import ImageShow from '@/components/dialogs/ImageShow.vue'
import timeMixins from '@/mixins/time'
import vueSignature from 'vue-signature'
import { debounce } from 'lodash'
export default {
  name: 'order-dialog',
  components: { ImageShow, vueSignature },
  mixins: [timeMixins],
  props: {
    value: { type: Boolean, default: false },
    orderID: { type: String },
    type: { type: Number, default: 0 },
    // type types:
    // {
    //   0:'orders',
    //   1:'Departments',
    //   2:'Collecting',
    //   3:'supply',
    //   4:'shipment'
    // }
  },
  data() {
    return {
      receiptId: null,
      receiver: null,
      Times,
    }
  },
  computed: {
    driver: {
      get() {
        return this.order.delivery.isOtherDriver
          ? 'else'
          : this.order.delivery.driver
      },
      async set(v) {
        if (v === 'else') {
          this.order.delivery.isOtherDriver = true
          this.order.delivery.driver = null
        } else {
          this.order.delivery.isOtherDriver = false
          this.order.delivery.driver = v
        }
        await this.$store.dispatch('order/update', {
          ...this.order,
        })
        this.$forceUpdate()
      },
    },
    otherDriver: {
      get() {
        return this.order.delivery.otherDriver
      },
      set: debounce(async function (v) {
        this.order.delivery.otherDriver = v
        await this.$store.dispatch('order/update', {
          ...this.order,
        })
      }, 500),
    },
    otherDriverPhone: {
      get() {
        return this.order.delivery.otherDriverPhone
      },
      set: debounce(async function (v) {
        this.order.delivery.otherDriverPhone = v
        await this.$store.dispatch('order/update', {
          ...this.order,
        })
      }, 500),
    },

    drivers() {
      return [...this.$store.getters.drivers, { _id: 'else', fullname: 'אחר' }]
    },
    order() {
      return this.$store.getters.ordersMap[this.orderID]
    },
    shipperCollected: {
      get() {
        return !!this.order.delivery.deliveryManPickupTime
      },
      async set(v) {
        const valueToSet = v ? `${new Date().getTime()}` : ''
        this.order.delivery.deliveryManPickupTime = valueToSet
        await this.$store.dispatch('order/update', {
          ...this.order,
        })
        this.$forceUpdate()
      },
    },
    isNotPc() {
      return this.$vuetify.breakpoint.width < 960
    },
    receiptIdComputed: {
      get() {
        return this.order.paymentData.receiptId || this.receiptId
      },
      set(v) {
        this.receiptId = v
      },
    },
    collectedAll() {
      // this feature is disabled for now
      return true
      // return this.order.menu.products.every(product => product.isPicked)
    },
    receiverComputed: {
      get() {
        return this.order.delivery.receiver || this.receiver
      },
      set(v) {
        this.receiver = v
      },
    },
    tableHeaders() {
      const result = []
      if (this.uiAuth({ type: this.type, authArray: [2] })) {
        result.push({
          text: '',
          value: 'checkbox',
          class: 'primary lighten-1',
        })
      }
      if (this.uiAuth({ type: this.type, authArray: [1, 4] })) {
        result.push({
          text: this.$t('generics.image'),
          value: 'image',
          class: 'primary lighten-1',
        })
      }
      result.push({
        text: this.$t('product.name'),
        value: 'product.title',
        class: 'primary lighten-1',
      })
      result.push({
        text: this.$t('generics.quantity'),
        value: 'quantity',
        class: 'primary lighten-1',
      })
      if (this.uiAuth({ type: this.type, authArray: [1, 2, 3, 4] })) {
        result.push({
          text: this.$t('generics.notes'),
          value: 'product.info',
          class: 'primary lighten-1',
        })
      }
      if (this.uiAuth({ type: this.type, authArray: [2] })) {
        result.push({
          text: '',
          value: 'notReadyFromKitchen',
          class: 'primary lighten-1',
        })
      }
      return result
    },
    pdfUrl() {
      return `${document.location.origin}/orders/pdf/${this.order._id}`
    },
    countedMenu() {
      return this.order.menu.products.map((product, i) => {
        product.count = i + 1
        return product
      })
    },
    totalPrice() {
      const { discount } = this.order.menu
      let total = 0
      this.order.menu.products.forEach(product => {
        total += product.product.price * product.quantity
      })
      if (discount) {
        const discountSum = Math.round((total * discount) / 100)
        total -= discountSum
      }
      return Math.round(total)
    },
    paymentStatus() {
      return {
        text: this.order.paymentData.isPayed
          ? this.$t('order.payed')
          : this.$t('order.notPayed'),
        status: this.order.paymentData.isPayed,
      }
    },
    active: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },

  methods: {
    numOfBoxes(order) {
      const products = order.menu.products
      return products.reduce((acc, curr) => {
        if (curr?.product?.box) return acc + curr?.quantity
        return acc
      }, 0)
    },
    uiAuth({ type, authArray }) {
      if (type === -1) return true
      return authArray.includes(type)
    },
    dayjs,
    async clientSign() {
      let signature
      if (this.order.signature) signature = false
      else signature = this.$refs.signature.save('image/jpeg')
      const response = await this.$store.dispatch('order/update', {
        ...this.order,
        signature,
        status: 'delivered',
      })
      this.$forceUpdate()
    },
    copyLink(link) {
      navigator.clipboard.writeText(link)
    },
    editOrder() {
      this.$router.push({
        name: 'BidHandler',
        params: { isNew: false, bid: this.order, isEditOrder: true },
      })
    },
    async handleProductPick(p) {
      await this.submit({
        productId: p._id,
        isPicked: p['isPicked'],
      })
      this.$forceUpdate()
    },
    async submit(item) {
      await this.$store.dispatch('order/toggle-picked', {
        _id: this.order._id,
        ...item,
        toastOptions: { disabled: true },
      })
    },
    convertDate(date) {
      return Times.formatedDay(date)
    },
    itemRowBackground(item) {
      if (item.count % 2 != 0) {
        return 'greyHighlight'
      }
      return 'white'
    },
    async approvePayment() {
      if (this.order.paymentData.receiptId) {
        await this.$store.dispatch('order/update', {
          ...this.order,
          paymentData: { receiptId: '', isPayed: false },
        })
        this.receiptId = ''
        this.$forceUpdate()
      } else {
        if (!this.receiptId) return

        await this.$store.dispatch('order/approvalPayment', {
          id: this.order._id,
          receiptId: this.receiptId,
          order: this.order,
        })
        this.order.paymentData.isPayed = true
        this.order.paymentData.receiptId = this.receiptId
        this.$forceUpdate()
      }
    },
    async approveDeliveryHandler() {
      if (this.order.delivery.receiver) {
        await this.$store.dispatch('order/update', {
          ...this.order,
          delivery: { ...this.order.delivery, receiver: '' },
        })
        this.receiver = ''
        this.$forceUpdate()
      } else {
        if (!this.receiver) return
        await this.$store.dispatch('order/deliveryApproval', {
          id: this.order._id,
          receiver: this.receiver,
          order: this.order,
        })
        this.order.delivery.receiver = this.receiver
        this.$forceUpdate()
      }
    },
  },
  mounted() {
    this.$store.dispatch('driver/index')
  },
}
</script>

<style lang="scss" scoped>
.payment-status {
  --color: var(--primary);
  border: var(--color) 5px solid !important;
  color: var(--color);
}

.payed-true {
  --color: var(--success);
  border: var(--color) 5px solid !important;
  color: var(--color);
}

.payed-false {
  --color: var(--error);
  border: var(--color) 5px solid !important;
  color: var(--color);
}
.card-border {
  border: lightGrey 1px solid;
}
.v-input-hours ::v-deep .v-input--radio-group__input {
  align-items: baseline;
  flex-direction: row !important;
  justify-items: center;
  justify-content: center;
}
.v-input-hours {
  font-size: 12px;
  margin-bottom: 12px;
}
.v-input-hours ::v-deep label {
  font-size: 14px !important;
  margin-right: -8px;
  margin-left: 12px;
}
</style>

<style lang="scss">
//only way to the the text field inline with row
.order-dialog {
  div.v-text-field__details {
    display: none !important;
  }

  .v-input__slot {
    margin: 0 !important;
  }
}
@media (max-width: 960px) {
  .v-card.order-dialog {
    padding: 0 36px 1px 36px !important;
  }
  .flex-column-not-pc {
    flex-direction: column !important;
  }
  .align-center-not-pc {
    align-items: center !important;
  }
}
</style>
