var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"loading":_vm.loading,"search":_vm.search,"items-per-page":20,"sort-by":"delivery","id":"orders-table","item-class":_vm.itemRowBackground,"footer-props":{ 'items-per-page-options': [100, 30, 50, -1] }},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.notes",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"max-w-100"},[_vm._v(_vm._s(item.delivery.info))])]}},{key:"item.delivery",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-dlex column"},[_c('div',[_vm._v(_vm._s(_vm.getOrderDay(item.delivery)))]),_c('div',[_vm._v(_vm._s(_vm.getOrderDayString(item.delivery)))]),_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.delivery.adjustedStartHour)+" ")])])]}},{key:"item.box",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numOfBoxes(item))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'delivered')?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-minus")])]}}])}),(_vm.orderHandlerDialog)?_c('order-handler',{attrs:{"orderID":_vm.chosenOrder,"type":-1},model:{value:(_vm.orderHandlerDialog),callback:function ($$v) {_vm.orderHandlerDialog=$$v},expression:"orderHandlerDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }