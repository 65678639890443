import myAxios from '../../services/myAxios'

export default {
  state: {
    menu: null, //chosen menu
    menus: [],
    filtered: [],
  },
  getters: {
    menus: state => state.menus,
    menu: state => state.menu,
    menusFiltered: state => state.filtered,
  },
  mutations: {
    //sets all menus
    'menus/set': (state, payload) => {
      state.menus = payload
      state.filtered = [...state.menus]
    },
    //sets one menu
    'menu/set': (state, payload) => (state.menu = payload),
    //filters the menu's array by menu's key and menu's val
    'menus/filter': (state, { key, val }) => {
      state.filtered = !val
        ? [...state.menus]
        : state.menus.filter(f => f[key] === val)
    },
    //store one menu
    'menu/store': (state, payload) => state.menus.push(payload),
    //destroys one menu
    'menu/destroy': (state, id) =>
      (state.menus = state.menus.filter(item => {
        return item._id !== id
      })),
    //updates one menu
    'menu/update': (state, payload) => {
      state.menus = state.menus.map(item => {
        if (item._id === payload._id) {
          return payload
        }
        return item
      })
    },
  },
  actions: {
    //fetch all menus
    'menu/index': async context => {
      const { data } = await myAxios.get('/menu')
      context.commit('menus/set', data)
    },
    //fetch one menu by id
    'menu/show': async (context, id) => {
      let { data } = await myAxios.get('/menu/' + id)
      context.commit('menu/set', data)
    },
    //stores one menu
    'menu/store': async (context, payload) => {
      let { data } = await myAxios.post('/menu', { ...payload })
      context.commit('menu/store', data)
    },
    //destroys one menu
    'menu/destroy': async (context, id) => {
      await myAxios.delete('/menu/' + id)
      context.commit('menu/destroy', id)
    },
    //updates one menu by its id
    'menu/update': async (context, payload) => {
      await myAxios.put('/menu/' + payload._id, payload)
      context.commit('menu/update', payload)
    },
    'menu/sendEmail': async (context, payload) => {
      await myAxios.post('/menu/sendEmail', payload)
    },
  },
}
